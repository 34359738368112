import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import { useFiltersUrlParams } from '@hooks/useFiltersUrlParams';
import LargeFiltersChipIndicator
  from '@pages/Invoices/components/TableView/Filters/components/LargeFiltersChipIndicator';
import { processDateFilters } from '@pages/Invoices/components/TableView/Filters/components/LargeSizeTableFilters';
import { useInvoicesContext } from '@pages/Invoices/context';
import { ShowFiltersModalId } from '@pages/Invoices/modals/ShowFiltersModal';
import {
  StyledClearAllButton,
  StyledFiltersIconButton,
  StyledSearchTextField
} from '@pages/Invoices/styled';
import { convertIsoToReadable } from '@utils/formatTime';

import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Box, FormControl, InputAdornment, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const SmallSizeTableFilters: FC = () => {
  const {
    setStartDate,
    setEndDate,
    setSupportCategory,
    setSearchField,
    searchField,
    serviceStartDate,
    serviceEndDate,
    supportCategory,
    resetFilters,
  } = useFiltersUrlParams();
  const [toggleFilters, setToggleFilters] = useState(false);
  const { t } = useTranslation();
  const { categoriesOptions } = useInvoicesContext();

  const allFilters = useMemo(
    () => [
      {
        id: 'search',
        value: searchField,
        reset: () => setSearchField()
      },
      {
        id: 'start-date',
        value: convertIsoToReadable(serviceStartDate, { fullYear: true }),
        reset: () => setStartDate()
      },
      {
        id: 'end-date',
        value: convertIsoToReadable(serviceEndDate, { fullYear: true }),
        reset: () => setEndDate()
      },
      {
        id: 'category',
        value: categoriesOptions
          ?.filter(opt => supportCategory?.includes(String(opt.id)))
          .map(cat => cat.name)
          .join(', '),
        reset: () => setSupportCategory()
      }
    ],
    [
      categoriesOptions,
      searchField,
      serviceEndDate,
      serviceStartDate,
      setEndDate,
      setSearchField,
      setStartDate,
      setSupportCategory,
      supportCategory
    ]
  );

  const updatedFilters = processDateFilters(allFilters);
  const filtersApplied = updatedFilters.filter(f => f.value && f.value?.length > 0);

  const handleShowFiltersModal = useCallback(
    async () => {
      setToggleFilters(true)
      await NiceModal.show(ShowFiltersModalId, {
        categoriesOptions,
        handleStartDate: setStartDate,
        handleEndDate: setEndDate,
        handleSupportCategory: setSupportCategory,
        serviceStartDate,
        serviceEndDate,
        supportCategory,
      })
      setToggleFilters(false)
    },
    [categoriesOptions, serviceEndDate, serviceStartDate, setEndDate, setStartDate, setSupportCategory, supportCategory],
  );

  return (
    <Box width="100%" pt="10px" pb={3} display="flex" flexDirection='column'>
      <Box display="flex" gap="12px" width="100%" alignItems="center" justifyContent='end'>
        <FormControl sx={{ width: '100%' }} variant="outlined">
          <StyledSearchTextField
            name="search"
            placeholder={t('tableFilters.searchLabel')}
            value={searchField || ''}
            inputProps={{ maxLength: 50 }}
            onChange={ev => setSearchField(ev.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color={searchField ? 'primary' : 'inherit'} />
                </InputAdornment>
              ),
              endAdornment: searchField && (
                <InputAdornment position="end">
                  <IconButton disableRipple onClick={() => setSearchField('')}>
                    <CloseIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              )
            }}
            variant="outlined"
          />
        </FormControl>

        <StyledFiltersIconButton
          disableRipple
          onClick={handleShowFiltersModal}
          isExpanded={toggleFilters}
        >
          <FilterAltIcon color="primary" />
        </StyledFiltersIconButton>
      </Box>
      {filtersApplied.length > 0 && (
        <Box display="flex" alignItems="center" mt={3} gap={3} flexWrap="wrap">
          <Typography fontSize={14} fontWeight={400} whiteSpace="nowrap">
            {t('tableFilters.appliedFilters')}
          </Typography>
          {filtersApplied.map(f => (
            <LargeFiltersChipIndicator filterValue={f.value as string} handleClearFilter={f.reset}
                                       icon={f.id === 'search' ? 'search' : 'default'} />
          ))}
          <StyledClearAllButton
            disableRipple
            onClick={resetFilters}
            variant="outlined"
            color="primary"
            endIcon={<ClearIcon />}
          >
            <Typography fontSize={13} whiteSpace="nowrap">
              {t('tableFilters.clearAllFilters')}
            </Typography>
          </StyledClearAllButton>
        </Box>
      )}
    </Box>
  );
};

export default memo(SmallSizeTableFilters);
