import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFiltersUrlParams } from '@hooks/useFiltersUrlParams';
import LargeFiltersChipIndicator
  from '@pages/Invoices/components/TableView/Filters/components/LargeFiltersChipIndicator';
import { MenuProps } from '@pages/Invoices/constants';
import { useInvoicesContext } from '@pages/Invoices/context';
import { useCategorySelect } from '@pages/Invoices/hooks/useCategorySelect';
import {
  StyledCategoryFormControl,
  StyledClearAllButton,
  StyledLargeAdditionalFiltersBox
} from '@pages/Invoices/styled';
import { convertIsoToReadable } from '@utils/formatTime';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Checkbox, Divider,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface FilterItem {
  id: string;
  value: string | undefined;
  reset: () => void;
}

export const processDateFilters = (filters: FilterItem[]): FilterItem[] => {
  const startDateFilter = filters.find(filter => filter.id === 'start-date');
  const endDateFilter = filters.find(filter => filter.id === 'end-date');

  if (startDateFilter?.value && endDateFilter?.value) {
    const dateRangeFilter = {
      id: 'date-range',
      value: `${startDateFilter.value} - ${endDateFilter.value}`,
      reset: () => {
        startDateFilter.reset();
        endDateFilter.reset();
      }
    };

    return filters
      .filter(
        filter =>
          filter.id !== 'start-date' && filter.id !== 'end-date' && filter.value !== undefined && filter.value !== ''
      )
      .concat(dateRangeFilter);
  }

  return filters.filter(filter => filter.value !== undefined && filter.value !== '');
};

const LargeSizeTableFilters: FC = () => {
  const {
    setStartDate,
    setEndDate,
    setSupportCategory,
    searchField,
    setSearchField,
    serviceStartDate,
    serviceEndDate,
    supportCategory,
    resetFilters
  } = useFiltersUrlParams();
  const { t } = useTranslation();
  const { categoriesOptions, showFilters } = useInvoicesContext();

  const allFilters = useMemo(
    () => [
      {
        id: 'search',
        value: searchField,
        reset: () => setSearchField()
      },
      {
        id: 'start-date',
        value: convertIsoToReadable(serviceStartDate, { fullYear: true }),
        reset: () => setStartDate()
      },
      {
        id: 'end-date',
        value: convertIsoToReadable(serviceEndDate, { fullYear: true }),
        reset: () => setEndDate()
      },
      {
        id: 'category',
        value: categoriesOptions
          ?.filter(opt => supportCategory?.includes(String(opt.id)))
          .map(cat => cat.name)
          .join(', '),
        reset: () => setSupportCategory()
      }
    ],
    [
      categoriesOptions,
      searchField,
      serviceEndDate,
      serviceStartDate,
      setEndDate,
      setSearchField,
      setStartDate,
      setSupportCategory,
      supportCategory
    ]
  );

  const updatedFilters = processDateFilters(allFilters);
  const filtersApplied = updatedFilters.filter(f => f.value && f.value?.length > 0);

  const { options, selectedIds, getDisplayText, handleCategoryChange } = useCategorySelect({
    categoriesOptions,
    supportCategory,
    setCategory: setSupportCategory
  });

  return (
    <Box width="100%" py="10px">
      {/* EXPANDED FILTERS */}
      {showFilters && (
        <StyledLargeAdditionalFiltersBox display="flex" mt="20px" gap='24px'>
          <Box>
            <Typography fontWeight={400} mb={2}>{t('tableFilters.filterByDate')}</Typography>
            {/*  start date */}
            <Box display="flex" gap={2}>
              <DatePicker
                label={t('tableFilters.supportDateFrom')}
                value={serviceStartDate ? new Date(serviceStartDate) : null}
                slots={{
                  openPickerIcon: CalendarTodayIcon
                }}
                maxDate={serviceEndDate ? new Date(serviceEndDate) : undefined}
                slotProps={{
                  textField: () => ({
                    sx: {
                      fontWeight: 400,
                      width: 250,
                      backgroundColor: 'white',
                      '& .MuiInputBase-root': {
                        fontWeight: 400
                      },
                      '& .MuiFormLabel-root': {
                        fontWeight: 400,
                        fontSize: '16px'
                      }
                    },
                    variant: 'outlined'
                  }),
                  field: { clearable: true, onClear: () => setStartDate(null) }
                }}
                onChange={v => {
                  if (v) {
                    setStartDate(v);
                  }
                }}
              />
              {/*  end date */}
              <DatePicker
                label={t('tableFilters.supportDateTo')}
                value={serviceEndDate ? new Date(serviceEndDate) : null}
                slots={{
                  openPickerIcon: CalendarTodayIcon
                }}
                minDate={serviceStartDate ? new Date(serviceStartDate) : undefined}
                slotProps={{
                  textField: () => ({
                    sx: {
                      fontWeight: 400,
                      width: 250,
                      backgroundColor: 'white',
                      '& .MuiInputBase-root': {
                        fontWeight: 400
                      },
                      '& .MuiFormLabel-root': {
                        fontWeight: 400,
                        fontSize: '16px'
                      }
                    },
                    variant: 'outlined'
                  }),
                  field: { clearable: true, onClear: () => setEndDate(null) }
                }}
                onChange={v => {
                  if (v) {
                    setEndDate(v);
                  }
                }}
              />
            </Box>
          </Box>

          <Divider orientation="vertical" sx={{ width: '1px', height: '96px'}} />
          {/*  category */}
          <Box width='100%'>
            <Typography fontWeight={400} mb={2}>{t('tableFilters.filterByCategory')}</Typography>
            <Box width="100%" display="flex">
              <StyledCategoryFormControl>
                <InputLabel id="multiple-checkbox-label">
                  <Typography fontWeight={400}>{t('dashboard.invoiceDetails.selectSupportCategory')}</Typography>
                </InputLabel>
                <Select
                  labelId="multiple-checkbox-label"
                  id="multiple-checkbox"
                  multiple
                  value={selectedIds}
                  onChange={handleCategoryChange}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label={t('dashboard.invoiceDetails.selectSupportCategory')}
                      sx={{
                        backgroundColor: 'white',
                        fontWeight: 400
                      }}
                    />
                  }
                  renderValue={selected => getDisplayText(selected)}
                  MenuProps={MenuProps}
                >
                  {options.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      <Checkbox checked={selectedIds.includes(option.id)} />
                      <ListItemText primary={option.label} sx={{ '& .MuiTypography-root': { fontWeight: 400 } }} />
                    </MenuItem>
                  ))}
                </Select>
              </StyledCategoryFormControl>
            </Box>
          </Box>
        </StyledLargeAdditionalFiltersBox>
      )}

      {filtersApplied.length > 0 && (
        <Box display="flex" alignItems="center" mt={3} gap={3} flexWrap="wrap">
          <Typography fontSize={14} fontWeight={400} whiteSpace="nowrap">
            {t('tableFilters.appliedFilters')}
          </Typography>
          {filtersApplied.map(f => (
            <LargeFiltersChipIndicator filterValue={f.value as string} handleClearFilter={f.reset}
                                       icon={f.id === 'search' ? 'search' : 'default'} />
          ))}
          <StyledClearAllButton
            disableRipple
            onClick={resetFilters}
            variant="outlined"
            color="primary"
            endIcon={<ClearIcon />}
          >
            <Typography fontSize={13} whiteSpace="nowrap">
              {t('tableFilters.clearAllFilters')}
            </Typography>
          </StyledClearAllButton>
        </Box>
      )}
    </Box>
  );
};

export default memo(LargeSizeTableFilters);
