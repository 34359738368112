import styled from '@mui/system/styled';

const ThickMenuIcon = styled(props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.5 6.66634H2.5V3.33301H17.5V6.66634ZM17.5 8.33301H2.5V11.6663H17.5V8.33301ZM17.5 13.333H2.5V16.6663H17.5V13.333Z"
      fill="#8435E9"
    />
  </svg>
))({});

export default ThickMenuIcon;
