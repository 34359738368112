import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiCategoriesListApiResponse } from '@api/api';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { MenuProps } from '@pages/Invoices/constants';
import { useCategorySelect } from '@pages/Invoices/hooks/useCategorySelect';
import {
  StyledCategoryFormControl,
  StyledIconButton,
  StyledModal,
  StyledModalTitleBox,
  StyledTextButton,
} from '@pages/Invoices/styled';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface ShowFiltersModalProps {
  categoriesOptions?: ApiCategoriesListApiResponse;
  handleStartDate: (value?: Date | null) => void;
  handleEndDate: (value?: Date | null) => void;
  handleSupportCategory: (value?: string) => void;
  serviceStartDate?: string;
  serviceEndDate?: string;
  supportCategory?: string;
}

const ShowFiltersModal = NiceModal.create(
  ({
    categoriesOptions,
    handleStartDate,
    handleEndDate,
    handleSupportCategory,
    serviceStartDate,
    serviceEndDate,
    supportCategory,
  }: ShowFiltersModalProps) => {
    const { t } = useTranslation();
    const modal = useModal();
    const { downSm } = useBreakpoints();

    const [startModalDate, setStartModalDate] = useState<Date | null>(
      serviceStartDate ? new Date(serviceStartDate) : null,
    );
    const [endModalDate, setEndModalDate] = useState<Date | null>(serviceEndDate ? new Date(serviceEndDate) : null);
    const [categoryModal, setCategoryModal] = useState(supportCategory);

    const { options, selectedIds, getDisplayText, handleCategoryChange } = useCategorySelect({
      categoriesOptions,
      supportCategory: categoryModal,
      setCategory: setCategoryModal,
    });

    const handleConfirmClick = async () => {
      handleStartDate(startModalDate);
      handleEndDate(endModalDate);
      handleSupportCategory(categoryModal);
      modal.resolve(true);
      modal.remove();
    };

    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal disableRestoreFocus open={modal.visible} size={downSm ? 'xs' : 'small'} onClose={handleCancelClick}>
        <StyledModalTitleBox display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize={18} fontFamily="WFVisualSans">
            {t('tableFilters.filterInvoices')}
          </Typography>

          <StyledIconButton disableRipple onClick={handleCancelClick} sx={{ '& svg': { color: '#5A1ED3' } }}>
            <CloseIcon />
          </StyledIconButton>
        </StyledModalTitleBox>

        <Box padding="30px 20px">
          <Box display="flex" gap={2} flexDirection={downSm ? 'column' : 'row'} mb={3}>
            <DatePicker
              label={t('tableFilters.supportDateFrom')}
              value={startModalDate ? new Date(startModalDate) : null}
              slots={{
                openPickerIcon: CalendarTodayIcon,
              }}
              slotProps={{
                textField: () => ({
                  sx: {
                    fontWeight: 400,
                    width: 250,
                    backgroundColor: 'white',
                    '& .MuiInputBase-root': {
                      fontWeight: 400,
                    },
                    '& .MuiFormLabel-root': {
                      fontWeight: 400,
                      fontSize: '16px',
                    },
                  },
                  variant: 'outlined',
                }),
                field: { clearable: true, onClear: () => setStartModalDate(null) },
              }}
              onChange={v => {
                if (v) {
                  setStartModalDate(v);
                }
              }}
            />
            {/*  end date */}
            <DatePicker
              label={t('tableFilters.supportDateTo')}
              value={endModalDate ? new Date(endModalDate) : null}
              slots={{
                openPickerIcon: CalendarTodayIcon,
              }}
              slotProps={{
                textField: () => ({
                  sx: {
                    fontWeight: 400,
                    width: 250,
                    backgroundColor: 'white',
                    '& .MuiInputBase-root': {
                      fontWeight: 400,
                    },
                    '& .MuiFormLabel-root': {
                      fontWeight: 400,
                      fontSize: '16px',
                    },
                  },
                  variant: 'outlined',
                }),
                field: { clearable: true, onClear: () => setEndModalDate(null) },
              }}
              onChange={v => {
                if (v) {
                  setEndModalDate(v);
                }
              }}
            />
          </Box>
          <Box width="100%">
            <StyledCategoryFormControl fullWidth sx={{ maxWidth: '100%' }}>
              <InputLabel id="multiple-checkbox-label">
                <Typography fontWeight={400}>{t('dashboard.invoiceDetails.selectSupportCategory')}</Typography>
              </InputLabel>
              <Select
                labelId="multiple-checkbox-label"
                id="multiple-checkbox"
                multiple
                fullWidth
                value={selectedIds}
                onChange={handleCategoryChange}
                input={
                  <OutlinedInput
                    fullWidth
                    id="select-multiple-chip"
                    label={t('dashboard.invoiceDetails.selectSupportCategory')}
                    sx={{
                      backgroundColor: 'white',
                      fontWeight: 400,
                    }}
                  />
                }
                renderValue={selected => getDisplayText(selected)}
                MenuProps={MenuProps}
              >
                {options.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox checked={!!(categoryModal && categoryModal.includes(String(option.id)))} />
                    <ListItemText primary={option.label} sx={{ '& .MuiTypography-root': { fontWeight: 400 } }} />
                  </MenuItem>
                ))}
              </Select>
            </StyledCategoryFormControl>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p="20px">
          <StyledTextButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            variant="text"
            onClick={handleCancelClick}
            sx={{ height: 36, p: '4px' }}
          >
            <Typography fontSize={14}>{t('common.cancel')}</Typography>
          </StyledTextButton>
          <Button
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={handleConfirmClick}
            sx={{ maxWidth: '125px', height: 36, padding: '6px 16px' }}
            color="primary"
          >
            <Typography fontSize={14}>{t('tableFilters.applyFilters')}</Typography>
          </Button>
        </Box>
      </StyledModal>
    );
  },
);

export const ShowFiltersModalId = 'ShowFiltersModal';

NiceModal.register(ShowFiltersModalId, ShowFiltersModal);
