import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

const NoInvoicesMessage: FC = () => {
  const { t } = useTranslation();
  return (
    <Box display='flex' justifyContent='center' alignItems='center' mx='20px' mt='-40px' py='10px' bgcolor='#F6F6F7'>
      <Typography fontSize={14} fontWeight={400} fontFamily="WFVisualSans">{t('dashboard.invoicesMessage')}</Typography>
    </Box>
  );
};

export default memo(NoInvoicesMessage);
