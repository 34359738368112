import { useTranslation } from 'react-i18next';
import { InvoiceRead } from '@api/api';
import { StyledChip, StyledOutlinedDarkChip, StyledSecondaryChip } from '@pages/InvoiceDetails/components/styled';
import { StyledErrorDueDateChip } from '@pages/InvoiceDetails/components/TimelineTab/styled';
import { getDueDaysLeft } from '@pages/InvoiceDetails/utils';
import { formatCurrency } from '@utils/formatCurrency';
import { convertIsoToReadable } from '@utils/formatTime';

import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import OverflowTip from '@components/OverflowTip';

interface ExtendedInvoiceRead extends InvoiceRead {
  is_complete: number;
}

export const useListColumns = (): GridColDef[] => {
  const { t } = useTranslation();

  return [
    {
      field: 'created_at',
      headerName: 'Date added',
      hideable: true,
      sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 140,
      headerClassName: 'additional-padding',
      cellClassName: 'additional-padding',
      renderCell: ({ row }: GridCellParams<ExtendedInvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2">{convertIsoToReadable(row.created_at, { fullYear: true })}</Typography>
        </OverflowTip>
      )
    },
    {
      field: 'total_amount',
      headerName: 'Amount',
      hideable: true,
      sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }: GridCellParams<ExtendedInvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2">
            {`${row.currency_symbol ? row.currency_symbol : ''}${formatCurrency(row.total_amount)}`}
          </Typography>
        </OverflowTip>
      )
    },
    {
      field: 'vendor_name',
      headerName: 'Provider',
      hideable: true,
      sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }: GridCellParams<ExtendedInvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2" component="span">
            {row.vendor_name}
          </Typography>
        </OverflowTip>
      )
    },
    {
      field: 'service_exact_date',
      headerName: 'Support date',
      hideable: true,
      sortable: true,
      resizable: true,
      minWidth: 220,
      flex: 1,
      valueGetter: (_value, row: ExtendedInvoiceRead) => {
        if (row.service_exact_date) {
          return new Date(row.service_exact_date).getTime();
        }
        if (row.service_start_date && row.service_end_date) {
          return new Date(row.service_start_date).getTime();
        }
        return Infinity;
      },
      renderCell: ({ row }: GridCellParams<ExtendedInvoiceRead>) => {
        const dateToShow = row.service_exact_date
          ? convertIsoToReadable(row.service_exact_date, { fullYear: true })
          : (row.service_start_date && row.service_end_date)
            ? `${convertIsoToReadable(row.service_start_date, { fullYear: true })} - ${convertIsoToReadable(row.service_end_date, { fullYear: true })}`
            : null;
        return (
          <OverflowTip>
            <Box display="flex" gap={1}>
              <Typography variant="body2">
                {dateToShow ||
                  <Typography variant="body2" color="textSecondary">{t('common.actionRequired')}</Typography>}
              </Typography>
            </Box>
          </OverflowTip>
        );
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      hideable: true,
      sortable: false,
      resizable: true,
      minWidth: 300,
      flex: 1,
      renderCell: ({ row }: GridCellParams<ExtendedInvoiceRead>) => (
        <OverflowTip>
          <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {row.description || t('common.none')}
          </Typography>
        </OverflowTip>
      )
    },
    {
      field: 'category',
      headerName: 'Support category',
      hideable: true,
      sortable: true,
      resizable: true,
      minWidth: 250,
      flex: 1,
      renderCell: ({ row }: GridCellParams<ExtendedInvoiceRead>) => (
        <OverflowTip>
          {!row.category && (
            <Typography variant="body2" color="textSecondary">
              {t('common.actionRequired')}
            </Typography>
          )}
          {row.category && (
            <Typography mr={5} variant="body2" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
              {row.category.name}
            </Typography>
          )}
        </OverflowTip>
      )
    },
    {
      field: 'is_complete',
      headerName: 'Details status',
      hideable: true,
      sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }: GridCellParams<ExtendedInvoiceRead>) => (
        <>
          {row.is_complete ? (
            <StyledSecondaryChip
              color="secondary"
              variant="filled"
              label={t('dashboard.invoiceDetails.incomplete')}
            />
          ) : (
            <StyledChip
              variant="filled"
              color="primary"
              isDark
              label={t('dashboard.invoiceDetails.complete')}
              icon={<CheckIcon />}
            />
          )}
        </>
      )
    },
    {
      field: 'claimed_date',
      headerName: 'Claim status',
      hideable: true,
      sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }: GridCellParams<ExtendedInvoiceRead>) => (
        <>
          {row.claimed_date ? (
            <StyledChip
              variant="filled"
              color="primary"
              isDark
              label={t('dashboard.invoiceDetails.claimed')}
              icon={<CheckIcon />}
            />
          ) : (
            <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notClaimed')} />
          )}
        </>
      )
    },
    {
      field: 'is_paid',
      headerName: 'Paid status',
      hideable: true,
      sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (_value, row: ExtendedInvoiceRead) => {
        if (row?.is_paid) {
          return t('dashboard.invoiceDetails.paid');
        }
        if (!row?.due_date) {
          return t('dashboard.invoiceDetails.notPaid');
        }
        const daysLeft = getDueDaysLeft(row?.due_date);
        if (daysLeft === 0) {
          return t('dashboard.invoiceDetails.dueToday');
        }
        if (daysLeft > 0 && daysLeft <= 10) {
          return t('dashboard.invoiceDetails.dueSoon');
        }
        if (daysLeft > 10) {
          return t('dashboard.invoiceDetails.notPaid');
        }
        return t('dashboard.invoiceDetails.overdue');
      },
      renderCell: ({ row }: GridCellParams<ExtendedInvoiceRead>) => (
        <>
          {row?.is_paid ? (
            <StyledChip
              isDark
              color="primary"
              variant="filled"
              label={t('dashboard.invoiceDetails.paid')}
              icon={<CheckIcon />}
            />
          ) : !row?.due_date ? (
            <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notPaid')} />
          ) : getDueDaysLeft(row?.due_date) === 0 ? (
            <StyledErrorDueDateChip label={t('dashboard.invoiceDetails.dueToday')} color="error" variant="outlined" />
          ) : getDueDaysLeft(row?.due_date) > 0 && getDueDaysLeft(row?.due_date) <= 10 ? (
            <StyledErrorDueDateChip label={t('dashboard.invoiceDetails.dueSoon')} color="error" variant="outlined" />
          ) : getDueDaysLeft(row?.due_date) > 10 ? (
            <StyledOutlinedDarkChip variant="outlined" label={t('dashboard.invoiceDetails.notPaid')} />
          ) : (
            <StyledErrorDueDateChip label={t('dashboard.invoiceDetails.overdue')} color="error" variant="filled" />
          )}
        </>
      )
    }
  ];
};
