import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiInvoicesEventsCreateApiArg } from '@api/api';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { ManualEventActions } from '@pages/InvoiceDetails/components/TimelineTab/types';
import { StyledTextButton } from '@pages/InvoiceDetails/styled';
import { format } from 'date-fns';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingButton from '@components/LoadingButton';

import { StyledAddModalRegularTextField, StyledIconButton, StyledModal, StyledModalTitleBox } from '../styled';

interface AddEventModalProps {
  eventType: ManualEventActions;
  createMutation: (payload: ApiInvoicesEventsCreateApiArg) => Promise<void>;
  invoiceId: number;
}

const AddEventModal = NiceModal.create(({ eventType, createMutation, invoiceId }: AddEventModalProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [nameValue, setNameValue] = useState<string>('');
  const [dateValue, setDateValue] = useState<Date | null>(null);
  const [detailsValue, setDetailsValue] = useState<string>('');
  const { downSm } = useBreakpoints();

  const isCustomEvent = eventType === 'custom_event';
  const isPaymentDueEvent = eventType === 'payment_due_date';
  const isProviderPaidEvent = eventType === 'invoice_paid';
  const isClaimedEvent = eventType === 'claim_submitted';

  const handleConfirmClick = async () => {
    setIsLoading(true);
    const payload = {
      invoiceId,
      invoiceEventRequest: {
        type: eventType,
        is_manual: true,
        ...(dateValue && { date: format(dateValue, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") }),
        ...(detailsValue && { details: detailsValue }),
        ...(isCustomEvent && nameValue && { name: nameValue }),
      },
    };

    await createMutation(payload);
    modal.resolve(true);
    setIsLoading(false);
    modal.remove();
  };

  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  return (
    <StyledModal open={modal.visible} size={downSm ? 'xs' : 'small'} onClose={handleCancelClick}>
      <StyledModalTitleBox display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={18} fontFamily="WFVisualSans">
          {eventType === 'payment_due_date'
            ? t('dashboard.timeline.addDueDate')
            : t(`dashboard.timeline.modal.optionsAddModalTypeTitle.${eventType}`)}
        </Typography>

        <StyledIconButton disableRipple onClick={handleCancelClick} sx={{ '& svg': { color: '#5A1ED3' } }}>
          <CloseIcon />
        </StyledIconButton>
      </StyledModalTitleBox>

      <Box padding="24px 20px 30px">
        <Typography fontWeight={400} mb={4}>
          {t(
            eventType === 'custom_event' ? 'dashboard.timeline.modal.edit.subtitles.custom_event'
            : isPaymentDueEvent
              ? 'dashboard.timeline.modal.edit.subtitles.payment_due_date'
              : isClaimedEvent || isProviderPaidEvent
                ? 'dashboard.timeline.modal.subtitles.optional'
                : 'dashboard.timeline.modal.detailsOptional',
          )}
        </Typography>
        {isCustomEvent && (
          <Box display="flex" flexDirection="column">
            <StyledAddModalRegularTextField
              aria-label={`${eventType} name field`}
              variant="outlined"
              label={t('common.inputs.title')}
              type="text"
              value={nameValue}
              onWheel={e => (e.target as HTMLElement).blur()}
              onChange={e => {
                setNameValue(e.target.value);
              }}
            />
          </Box>
        )}

        {isCustomEvent && <Divider sx={{ my: 4 }} />}

        {!isPaymentDueEvent && isCustomEvent && (
          <Typography fontSize={16} fontWeight={400} mb={3}>
            {t(`dashboard.timeline.modal.subtitles.${isCustomEvent ? eventType : 'optional'}`)}
          </Typography>
        )}

        <Box>
          <DatePicker
            value={dateValue}
            onChange={newDate => setDateValue(newDate)}
            label={t(`dashboard.timeline.modal.datesField.${eventType}`)}
            slots={{
              openPickerIcon: CalendarTodayIcon,
            }}
            slotProps={{
              textField: () => ({
                variant: 'outlined',
                color: 'primary',
                sx: {
                  '& .MuiInputBase-root': {
                    fontWeight: 400,
                    fontSize: '16px',
                  },
                  '& .MuiFormLabel-root': {
                    fontWeight: 400,
                    fontSize: '16px',
                  },
                  '&.MuiFormControl-root': { width: eventType === 'custom_event' ? '158px' : '200px' },
                },
              }),
            }}
          />
        </Box>

        {!isPaymentDueEvent && (
          <Box mt="25px">
            <StyledAddModalRegularTextField
              aria-label={`${eventType} details field`}
              variant="outlined"
              value={detailsValue}
              label={t(`dashboard.timeline.modal.detailsField.${eventType}`)}
              type="text"
              onWheel={e => (e.target as HTMLElement).blur()}
              onChange={e => {
                setDetailsValue(e.target.value);
              }}
            />
          </Box>
        )}
      </Box>
      <Divider />

      <Box display="flex" justifyContent="space-between" alignItems="center" p="20px">
        <StyledTextButton
          disableRipple
          disableElevation
          disableTouchRipple
          disableFocusRipple
          variant="text"
          color="primary"
          onClick={handleCancelClick}
          sx={{ maxHeight: '36px' }}
        >
          <Typography fontSize={14}>{t('common.cancel')}</Typography>
        </StyledTextButton>

        <LoadingButton
          fullWidth
          disableRipple
          disableElevation
          disableTouchRipple
          disableFocusRipple
          variant="contained"
          loading={isLoading}
          color="primary"
          onClick={handleConfirmClick}
          type="submit"
          sx={{
            maxHeight: '36px',
            maxWidth: eventType === 'custom_event' || eventType === 'payment_due_date' ? 70 : 170,
            boxShadow: 'none',
            padding: '6px 16px',
          }}
          disabled={eventType === 'custom_event' && !nameValue}
        >
          <Typography fontWeight={14}>
            {eventType === 'custom_event' || eventType === 'payment_due_date'
              ? t('common.save')
              : t(`dashboard.timeline.modal.optionsAddModalTypeTitle.${eventType}`)}
          </Typography>
        </LoadingButton>
      </Box>
    </StyledModal>
  );
});

export const AddEventModalId = 'AddEventModal';

NiceModal.register(AddEventModalId, AddEventModal);
