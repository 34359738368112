import { FC, memo } from 'react';
import { StyledFiltersAppliedChip } from '@pages/Invoices/styled';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';

interface LargeFiltersChipIndicatorProps {
  handleClearFilter: VoidFunction;
  filterValue: string;
  icon?: 'default' | 'search';
}

const LargeFiltersChipIndicator: FC<LargeFiltersChipIndicatorProps> = props => {
  const { handleClearFilter, filterValue, icon = 'default' } = props;

  return (
    <StyledFiltersAppliedChip
      icon={icon === 'default' ? <FilterAltIcon color="primary" /> : <SearchIcon color='primary' />}
      label={
        <Typography
          fontSize={13}
          fontWeight={400}
          maxWidth={170}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {filterValue}
        </Typography>
      }
      onDelete={handleClearFilter}
    />
  );
};

export default memo(LargeFiltersChipIndicator);
